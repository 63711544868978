export const slugToTeamMap = {
  "adana-demirspor": "Adana Demirspor",
  alanyaspor: "Alanyaspor",
  antalyaspor: "Antalyaspor",
  basaksehir: "Başakşehir",
  besiktas: "Beşiktaş",
  bodrumspor: "Bodrumspor",
  eyupspor: "Eyüpspor",
  fenerbahce: "Fenerbahçe",
  galatasaray: "Galatasaray",
  "gaziantep-fk": "Gaziantep FK",
  goztepe: "Göztepe",
  hatayspor: "Hatayspor",
  kayserispor: "Kayserispor",
  konyaspor: "Konyaspor",
  kasimpasa: "Kasımpaşa",
  rizespor: "Rizespor",
  sivasspor: "Sivasspor",
  samsunspor: "Samsunspor",
  trabzonspor: "Trabzonspor",
};
