import React, { useMemo } from "react";
import { Link, navigate } from "gatsby";
import { FaArrowRight, FaInfoCircle, FaVideo } from "react-icons/fa";

import Combobox from "../components/Combobox";
import ExternalLink from "../components/ExternalLink";
import { useRounds } from "../hooks/useRounds";
import { getMatchLink } from "../util/beinConnectUtil";
import { getDate, getTime } from "../util/dateUtil";
import { getMatchURL } from "../util/urlUtil";
import { logos } from "../util/logos";
import { slugToTeamMap } from "../util/teamUtil";

const upcomingWeeksText = "Güncel Maçlar";
const allWeeksText = "Tüm Haftalar";
const allTeamsText = "Tüm Takımlar";
const teamClass = "flex basis-1/2 items-center font-bold text-xs lg:text-base";
const TimeComponent = ({ date }) => (
  <div className="cursor-default flex p-3 items-center border-2 h-8 border-solid border-gray-300 text-xs">
    {getTime(new Date(date)) === "03:00" ? (
      <span className="w-8 text-center" title="Tarihi Belli Değil">
        TBD
      </span>
    ) : (
      <span className="w-8 text-center">{getTime(new Date(date))}</span>
    )}
  </div>
);

const Fixture = ({ title, matches, team, round, areAllMatchesPlayed }) => {
  const rounds = [
    areAllMatchesPlayed ? allWeeksText : upcomingWeeksText,
    ...useRounds().map((r) => r.value),
  ];
  const teams = useMemo(() => {
    const result = [...Object.values(slugToTeamMap)];
    result.sort((a, b) => a.localeCompare(b));

    return [allTeamsText, ...result];
  }, []);

  function setTeam(team) {
    if (team === allTeamsText) {
      navigate("/fikstur");
      return;
    }
    const slug = Object.keys(slugToTeamMap).filter(
      (key) => slugToTeamMap[key] === team
    )[0];

    navigate(`/fikstur/takimlar/${slug}`);
  }

  function setWeek(week) {
    if (week === rounds[0]) {
      navigate("/fikstur");
      return;
    }
    navigate(`/fikstur/haftalar/${week}`);
  }

  let curDay;
  let dateChanged;
  let curWeek;
  let weekChanged;

  return (
    <div className="flex flex-col w-full dark:bg-gray-900 dark:text-white">
      <h1 className="text-pink-500 font-bold text-center lg:mt-0 mt-4">
        {title}
      </h1>
      <div className="flex w-full"></div>
      <div className="w-full flex justify-center items-center py-8">
        <div className="flex flex-col">
          <div className="font-bold w-full flex justify-center">Haftalar</div>
          <Combobox
            data={rounds}
            selected={round || (team ? allWeeksText : upcomingWeeksText)}
            onSelect={setWeek}
          />
        </div>
        <div className="flex flex-col">
          <div className="font-bold w-full flex justify-center">Takımlar</div>
          <Combobox
            data={teams}
            selected={team || allTeamsText}
            onSelect={setTeam}
          />
        </div>
      </div>
      {matches.map((match, index) => {
        const matchEnded = match.ftScore.home !== null;

        if (getDate(new Date(match.date)) !== curDay) {
          dateChanged = true;
          curDay = getDate(new Date(match.date));
        } else {
          dateChanged = false;
        }
        if (match.round !== curWeek) {
          weekChanged = true;
          curWeek = match.round;
        } else {
          weekChanged = false;
        }
        return (
          <div key={index}>
            {weekChanged && (
              <div className="ml-4 mt-4 font-bold text-xl text-pink-500 font-semibold">
                {curWeek}. Hafta
              </div>
            )}
            {dateChanged && (
              <div className="p-4 flex items-center font-bold text-xl w-full border-0 border-b-2 border-solid border-gray-300 h-24">
                {curDay}
              </div>
            )}
            <Link to={getMatchURL(match.home.fullName, match.away.fullName)}>
              <div className="flex justify-center items-center w-full border-0 border-y-2 border-solid border-gray-300 h-12 hover:bg-gradient-to-r hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 hover:text-white">
                <div className={`${teamClass} justify-end`}>
                  <div className="mr-1 lg:mr-2 text-right ">
                    {match.home.fullName}
                  </div>
                  <img
                    width="30px"
                    height="30px"
                    src={logos[match.home.slug]}
                    alt={match.home.fullName}
                  />
                </div>
                {matchEnded ? (
                  <div className="flex items-center ">
                    <span className="bg-indigo-600 ml-2 font-semibold text-white w-5 h-5 flex justify-center items-center mr-0.5">
                      {match.ftScore.home}
                    </span>
                    <span className="bg-indigo-600 mr-2 font-semibold text-white w-5 h-5 flex justify-center items-center">
                      {match.ftScore.away}
                    </span>
                  </div>
                ) : (
                  <div className="w-15 mx-2">
                    <TimeComponent date={match.date} />
                  </div>
                )}
                <div className={`${teamClass} justify-start`}>
                  <img
                    width="30px"
                    height="30px"
                    src={logos[match.away.slug]}
                    alt={match.away.fullName}
                  />
                  <div className="ml-1 lg:ml-2">{match.away.fullName}</div>
                  {match.oldDate !== null && (
                    <FaInfoCircle
                      className="ml-2 h-5 w-5"
                      title={`Maç ${getDate(
                        new Date(match.oldDate)
                      )} tarihinden ertlenmiştir`}
                    />
                  )}
                  {match.note !== null && (
                    <FaInfoCircle className="ml-2 h-5 w-5" title={match.note} />
                  )}
                </div>
                {match.status === "FINISHED" && (
                  <ExternalLink
                    Icon={FaVideo}
                    path={getMatchLink(match)}
                    marginClass="mr-4"
                    textClass="flex justify-end"
                  />
                )}
                <FaArrowRight className="flex justify-end mr-8" />
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Fixture;
