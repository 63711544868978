import { useStaticQuery, graphql } from "gatsby";

export const useRounds = () => {
  const { allRoundsJson } = useStaticQuery(graphql`
    query {
      allRoundsJson(sort: { fields: value }) {
        nodes {
          value
        }
      }
    }
  `);

  return allRoundsJson.nodes;
};
