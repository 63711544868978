import React from "react";

import FixtureComponent from "../../components/Fixture";
import { SeoComponent } from "../../components/SeoComponent";
import { useAllMatches } from "../../hooks/useAllMatches";

const Fixture = () => {
  const date = new Date();
  date.setDate(date.getDate() - 4);
  const { allMatches, areAllMatchesPlayed } = useAllMatches();
  return (
    <FixtureComponent
      matches={
        areAllMatchesPlayed
          ? allMatches
          : allMatches.filter((match) => new Date(match.date) >= date)
      }
      areAllMatchesPlayed={areAllMatchesPlayed}
      title={"Süper Lig Fikstürü"}
    />
  );
};

export default Fixture;

export const Head = () => (
  <SeoComponent
    title="Süper Lig Fikstürü"
    description="Türkiye Futbol Süper Ligi 2024-25 Sezonu Sonuçlar ve Gelecek Maçlar"
    keywords="fikstür, fikstur, Süper lig fikstür, maç sonuçları, mac sonuclari, Süperlig, Superlig, ensuperlig, ensüperlig, puan durumu,
    Futbol, Maçlar, Maclar, Spor, Adana Demirspor, Alanyaspor, Antalyaspor, Başakşehir, Basaksehir, Beşiktaş, Besiktas, Bodrumspor, Eyüpspor,
    Eyupspor, Galatasaray, Fenerbahçe, Gaziantep, Gaziantep FK, Göztepe, Goztepe, Hatayspor, Kasımpaşa, Kasimpasa, Konyaspor,
    Rizespor, Sivasspor, Trabzonspor"
  />
);
